<template>
  <div class="flex flex-col items-center mb-2">
    <div class="w-full">
      <div class="bg-purple-500 p-5 text-white rounded-lg font-light">
        {{ text }}
        <strong class="font-bold"
          ><a href="/administration/membership">Mi Membresía</a></strong
        >.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaOnlyProInfo",
  props: {
    text: {
      type: String,
      default: () => "primary",
    },
  },
};
</script>
